.data-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .data-wrapper {
    padding: 100px 0 60px;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;

    .btn {
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: #000000;
      border-radius: 5px;
      padding: 10.5px 15px;
      width: 120px;
    }

    .btn-secondary {
      border: 1px solid #000000;
      background-color: #fafbf8;
    }

    .btn-primary {
      background-color: #000000;
      color: #ffffff;
      border-color: #000000;
    }

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
      margin-bottom: 24px;
    }

    input {
      padding: 14px 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      background-color: #ffffff;
      color: #000000;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 20px;
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
      }
    }

    label {
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 8px;
    }
  }
}
