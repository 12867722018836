button.add-time-cart-btn {
  border-radius: 2px;
  border: 1px solid #eaeaea;
  background: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.163px;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
