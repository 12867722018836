.search-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  &.hasMultipleCustomers {
    min-height: calc(100vh - 103px);
  }
  
  input {
    padding: 14px 16px;
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 30px;
    width: 100%;

    &:focus {
      border: 1px solid #F3F3F3;
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    }
  }
}