.notifications-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .notifications-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }
  }
}
