.orders-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .orders-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    .title-wrapper {
      margin-bottom: 46px;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }

    .table-cell {
      padding: 20px 30px;
      width: 15%;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0125em;
      color: #000000;

      &[data-status="on hold"] {
        color: #add8e6;
      }

      &[data-status="open"] {
        color: #fee12b;
      }

      &[data-status="shipping"] {
        color: #00008b;
      }

      &[data-status="completed"] {
        color: #1f9a41;
      }

      &[data-status="canceled"] {
        color: #f32d68;
      }

      &:last-child {
        padding: 20px 5px;
      }

      &.row-date,
      &.row-status {
        width: 17%;
      }

      &.row-action {
        width: 6%;
      }

      svg {
        border-radius: 50px;
      }

      @media screen and (max-width: 1199.98px) {
        width: 18%;

        &.row-date {
          width: 20%;
        }

        &.row-action {
          width: 8%;
        }
      }

      @media screen and (max-width: 991.98px) {
        width: 22%;

        &.row-date {
          width: 26%;
        }

        &.row-action {
          width: 8%;
        }
      }

      @media screen and (max-width: 767.98px) {
        width: 26%;

        &.row-date {
          width: 40%;
        }

        &.row-action {
          width: 8%;
        }
      }

      @media screen and (max-width: 575.98px) {
        width: 35%;

        &.row-date {
          width: 50%;
        }

        &.row-action {
          width: 15%;
        }
      }
    }

    .table-header {
      background-color: #f4f5f2;
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .table-cell {
        font-weight: 600;
      }
    }

    .table-details {
      display: flex;
      background-color: #ffffff;
      align-items: center;
      cursor: pointer;

      &:nth-child(even) {
        background-color: #fafbf8;
      }

      &:hover {
        background-color: #f4f5f2;

        .table-cell svg {
          background-color: #ffffff;
        }
      }
    }
  }
}
