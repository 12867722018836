.gamification-popover {
  width: 370px;
  max-width: 400px !important;
  border: 0 !important;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.05);

  .popover-header {
    padding: 20px;
    background: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    border-bottom: 0;
  }

  .popover-body {
    padding: 0;
  }

  .popover-arrow {
    display: none !important;
  }

  .linear-progressbar-box {
    padding: 40px 35px 10px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    .progress {
      height: 10px;
      border-radius: 0;

      .bg-warning {
        background: #9b51e0 !important;
      }
      .bg-info {
        background-color: #d9d9d9 !important;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%;
      li {
        flex: 1 auto;
        position: relative;
        font-size: 12px;

        &:first-child > .progress {
          border-radius: 2px 0 0 2px;
        }
        &:last-child > .progress {
          border-radius: 0 2px 2px 0;
        }

        > span {
          position: absolute;
          right: 0;
          top: -25px;
          transform: translateX(50%);
          color: #808080;
          font-weight: 400;
          letter-spacing: 0.15px;
        }

        &:first-child:before,
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 15px;
          background: #000;
          position: absolute;
          bottom: 0;
          right: 0;
        }

        &:first-child {
          &:before {
            left: 0;
          }
          span.initial-amount {
            left: -6px;
            transform: translateX(0);
          }
        }
      }
    }
  }
}

.gamification-block {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  transition: right ease-in 0.5s;

  &.cart-opened {
    right: 380px;
  }

  .RCP {
    position: relative;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    border-radius: 65px;
    position: relative;
    width: 78px !important;
    height: 78px !important;
    transition: 0.3s ease box-shadow;
    cursor: pointer;

    > svg {
      position: absolute;
      top: -19px;
      left: -19px;
    }

    .indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover,
  &:active,
  &:focus {
    .RCP {
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    }
  }
}
.gift-wrapper {
  padding-bottom: 20px;
}

.gift-level {
  position: relative;
  padding: 10px 28px;

  label {
    color: #303030;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
    padding-left: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      list-style: none;
      width: 33%;
      text-align: center;
    }
  }
  .gift-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .gift-image {
      border-radius: 5px;
      border: 1px solid #eaeaea;
      background: #fff;
      height: 60px;
      width: 60px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      padding: 7px;

      .checkIcon {
        position: absolute;
        top: -10px;
        right: -5px;
        display: none;
      }

      .lock-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .gift-name {
      color: #909090;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.15px;
    }

    &:not(.locked, .active) {
      &:hover,
      &:active,
      &:focus {
        .gift-image {
          border-color: #505050;
        }
        .gift-name {
          color: #505050;
        }
      }
    }

    &.active {
      .gift-image {
        border-color: #9b51e0;

        .checkIcon {
          display: block;
        }
      }
      .gift-name {
        font-weight: 600;
        color: #9b51e0;
      }
    }
  }

  .level-lock-overlay {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    .overlay-content {
      display: inline-flex;
      align-items: center;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: 0.175px;

      img {
        margin-right: 10px;
      }
    }
  }
}

.gift-wrapper .gift-level:last-child {
  margin-bottom: 0;

  .level-lock-overlay {
    border-bottom: 0;
  }
}

.amount-spent {
  color: #000;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.138px;
}
