.cart-drawer {
  position: relative;
  width: 0;
  overflow: hidden;
  transition: all ease-in 0.5s;

  .mobile-menu-header {
    padding: 28px 24px 28px 20px;
    border-bottom: 1px solid #f3f3f3;
    background: #ffffff;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.0125em;
      color: #000000;
    }

    svg {
      color: #000000;
    }
  }
  .cart-drawer-header {
    > div {
      padding: 15px 20px;
      border-bottom: 1px solid #f3f3f3;
    }
    h4 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.2px;
    }

    .cart-timmer {
      padding: 10px 20px;
      border-bottom: 1px solid #f3f3f3;
      font-size: 13px;
      font-weight: 500;

      .timmer {
        font-weight: 700;
        width: 33px;
        line-height: 1;
      }
    }
  }

  .cart-wrapper {
    position: fixed;
    height: calc(100vh - 67px);
    top: 67px;
    right: -370px;
    width: 100%;
    background-color: #fafbf8;
    border-left: 1px solid #f3f3f3;
    display: flex;
    flex-flow: column;
    transition: all ease-in 0.5s;
    width: 370px;
    z-index: 9;

    &.hasMultipleCustomers {
      height: calc(100vh - 98px);
      top: 98px;
    }

    @media screen and (max-width: 1200px) {
      width: 370px;
      right: -370px;
    }
  }

  .cart-item {
    display: flex;
    align-items: flex-start;
    padding: 20px 10px 20px 20px;
    border-bottom: 1px solid #f3f3f3;
    transition: all ease 0.8s;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    .cart-product-content {
      display: flex;
      align-items: flex-start;
    }
    .cart-image-action {
      .btn-remove {
        display: inline-block;
        svg {
          height: 30px;
          width: 30px;
        }
        &:hover {
          svg {
            color: #dc3644;
          }
        }
      }
    }

    .cart-item-name {
      display: block;
      cursor: pointer;
    }

    .cart-product-image {
      border-radius: 5px;
      background: #fff;
      margin-right: 10px;
      padding: 15px 5px;
    }

    .img-wrapper {
      width: 54px;
      padding-top: 52px;
      position: relative;
      overflow: hidden;
      height: fit-content;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .img-overlay {
        transition: all ease 0.8s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f32d68;
        opacity: 0;

        .btn-remove {
          border-radius: 40px;
          background-color: #ffffff;
          display: flex;
          cursor: pointer;
        }
      }
    }

    .cart-item-wrapper {
      flex: 1;
      display: flex;
      flex-flow: column;

      h6,
      p {
        color: #909090;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0.138px;
        margin-bottom: 4px;
      }

      label {
        color: #000;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.163px;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }
      .product-price-info label {
        color: #909090;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.15px;
        margin-right: 5px;

        &.offered-price-info {
          font-weight: 400;
          margin-right: 0;
        }
      }

      .green-tg {
        margin: 2px 0 0 5px;
        margin-left: 5px;
        font-size: 10px;
        text-align: center;
        width: 10px;
        max-height: 11px;
        border-radius: 1px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        padding: 5px 3px;

        &[data-phenotype="Indica"] {
          background-color: #511e84;
        }

        &[data-phenotype="Hybrid"] {
          background-color: #1fa824;
        }

        &[data-phenotype="Sativa"] {
          background-color: #cd001a;
        }

        &[data-phenotype="CBN"] {
          background-color: #72cddc;
        }

        &[data-phenotype="THCV"] {
          background-color: #74bf44;
        }

        &[data-phenotype="CBD"] {
          background-color: #9da7af;
        }
      }

      .qty-container {
        display: flex;
        margin-top: 4px;

        .qty-wrapper {
          display: flex;
          padding: 5px 7px;
          align-items: center;
          margin-right: 24px;
          height: fit-content;
          border-radius: 2px;
          border: 1px solid #eaeaea;
          background: #fff;

          .qty-value {
            padding: 0 10px;
            color: #505050;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0.163px;
            line-height: 1.2;
          }

          .qty {
            font-size: 18px;
            padding: 0px 5px;
            color: #505050;
            line-height: 1;
          }
        }

        .price-container {
          flex-flow: column;

          p {
            color: #505050;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.163px;
            margin-bottom: 0;
          }
        }
      }
    }

    &:hover {
      background-color: #f4f5f2;

      .img-wrapper {
        .img-overlay {
          opacity: 1;
        }
      }
      .cart-item-name {
        text-decoration: underline;
      }

      h6.cursor-pointer {
        text-decoration: underline;
      }
    }
  }

  .items-container {
    height: calc(100vh - 221px);
    overflow-y: auto;
  }

  .order-btn-container {
    padding: 0;
    border-top: 1px solid #f3f3f3;

    label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
    }

    p {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.175px;

      .origin {
        opacity: 0.5;
        text-decoration: line-through;
        margin-right: 6px;
      }
    }

    .action-button-wrapper {
      padding: 0 0 25px 0;

      .total-price {
        padding: 15px 20px;
      }
    }
  }

  &.active {
    // width: 300px;

    @media screen and (max-width: 1200px) {
      // width: 270px;
    }

    .cart-wrapper {
      right: 0;
    }
  }

  @media screen and (max-width: 767.98px) {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 12;

    .cart-wrapper {
      width: 100%;
      left: 100%;
      z-index: 3;
      position: absolute;
      top: 0 !important;
      height: 100% !important;
    }

    &.active {
      left: 0;
      width: 100%;

      .cart-wrapper {
        left: 0;
      }
    }
  }
}

.btn-review {
  padding: 0;
  border: 1px solid #000000;
  width: 100%;
  display: block;
  padding: 15px 30px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  span {
    display: block;
    width: 100%;
  }
}

.rebuild-cart-btn {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 2px;
  letter-spacing: 0.188px;
}

.cart-time-expired {
  position: relative;

  &.items-container {
    overflow: hidden !important;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: block;
    background: rgba(250, 251, 248, 0.6);
  }
}

.has-ar {
  &.hasMultipleCustomers {
    .cart-drawer .cart-wrapper {
      height: calc(100vh - 98px - 30px);
      top: calc(98px + 30px);
    }
  }
  .cart-drawer .cart-wrapper {
    height: calc(100vh - 98px);
    top: 98px;
  }
}

.no-items-text {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin-bottom: 15px;
}
