.fullpage-height {
  height: calc(100vh - 70px);
}

.account-list {
  padding-top: 40px;
  padding-bottom: 40px;
  > div {
    background: #fff;
    border: 1px solid #f1f1f1;
    font-size: 15px;
    color: #101010;

    svg {
      position: relative;
      transition: 0.2s all ease;
    }

    span {
      font-size: 13px;
      margin-left: 20px;
    }
    &:hover {
      color: #606060;

      svg {
        transform: translateX(5px);
      }
    }
    &:not(:last-child) {
      border-bottom: 0px;
    }
  }
}

.full-body {
  background-color: #fafbf8;
  min-height: 100vh;
}

.logout {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: #101010;

  &:hover {
    color: #606060;
  }
}
