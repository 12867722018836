span.plus-minus {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  background: #fff;
  height: 30px;
  width: 95px;
  margin-top: 5px;

  span {
    padding: 2px 10px;

    &.quantity-block {
      padding: 2px 0;
      min-width: 30px;
      text-align: center;
      color: #505050;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
    }
  }
}

.outofstock-item-wrapper .price-per-unit {
  .origin {
    text-decoration: line-through;
    margin-right: 3px;
  }
}
