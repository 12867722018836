.aware-button {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid;
}

.aware-button::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: var(--top);
  left: var(--left);
}

.aware-button.primary::after {
  background-color: #ffffff;
}

.aware-button.second::after {
  background-color: #000000;
}

.aware-button:hover span,
.aware-button:hover svg {
  position: relative;
  z-index: 1;
}

.aware-button.primary:hover,
.aware-button.primary:hover span {
  color: #000000;
}

.aware-button.second:hover span {
  color: #ffffff;

  path {
    fill: #fff;
    stroke: #000000;
  }
}
.aware-button.second:hover span {
  color: #ffffff;

  path {
    fill: #fff;
    stroke: #000000;
  }
}

.aware-button.second:hover {
  color: #ffffff !important;
}

.aware-button:hover::after {
  width: 225%;
  height: 562.5px;
}
.aware-button:active {
  &.primary {
    background-color: #ffffff;
  }

  &.second {
    background-color: #000000;
  }
}

.aware-button.btn-green {
  background: #219653;
  border-color: #219653;

  &:hover {
    color: #219653;
    span {
      color: #219653;
    }
  }
}

.aware-button {
  &.btn-green {
    background: #219653;
    border-color: #219653;
    color: #fff;

    &:hover {
      border-color: #219653;
      color: #219653;
      span {
        color: #219653;
      }
    }
  }

  &.btn-lg {
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    padding: 10px 60px;
  }
  &.btn-bottom {
    &:after {
      top: initial;
      left: initial;
    }
  }
  &.btn-outline {
    border: 1px solid #d0d0d0;
    background: rgb(255, 255, 255);
    &:after {
      background-color: #000;
    }
    &:hover {
      color: #fff;
      background: rgb(255, 255, 255);
      border: 1px solid #000;
      span {
        color: #fff;
      }
    }
  }
}
