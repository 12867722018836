.checkout-summary {
  @media (min-width: 1200px) {
    max-width: 670px;
    min-height: calc(100vh - 67px);
    height: 100%;
  }
}

.checkout-container {
  min-height: calc(100vh - 67px);
  height: 100%;

  .tag-green {
    margin-left: 5px;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    width: 10px;
    max-height: 11px;
    border-radius: 1px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    &[data-phenotype="Indica"] {
      background-color: #511e84;
    }

    &[data-phenotype="Hybrid"] {
      background-color: #1fa824;
    }

    &[data-phenotype="Sativa"] {
      background-color: #cd001a;
    }

    &[data-phenotype="CBN"] {
      background-color: #72cddc;
    }

    &[data-phenotype="THCV"] {
      background-color: #74bf44;
    }

    &[data-phenotype="CBD"] {
      background-color: #9da7af;
    }
  }

  h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;

    padding: 0 20px 15px;
    border-bottom: 1px solid #f3f3f3;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .checkout-summary {
    padding-top: 25px;
    padding-bottom: 25px;

    h4 {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      border: 0;
    }

    .check-card {
      margin-bottom: 20px;

      @media (min-width: 1200px) {
        margin-bottom: 50px;
      }

      .form-group {
        padding: 10px 10px;
        border: 1px solid #f3f3f3;
        background: #fafafa;

        @media (min-width: 992px) {
          padding: 20px 30px;
        }
      }

      .btn {
        color: #505050;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.175px;
        border-radius: 4px;
        padding: 10.5px 15px;
        width: 170px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 5px;
        }

        @media (max-width: 576px) {
          width: 160px;
        }
      }

      .maximum-character {
        float: right;
        margin-top: 10px;
      }

      .btn-secondary {
        border: 1px solid #000000;
        background-color: #fafbf8;
      }

      .btn-primary {
        background-color: #000000;
        color: #ffffff;
        border-color: #000000;
      }

      label {
        color: #909090;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.138px;
      }

      .form-group {
        .form-group-content {
          min-height: 40px;
        }
        p {
          color: #000;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.163px;
          text-transform: capitalize;
        }
      }

      input:not(input[type="checkbox"]),
      textarea {
        padding: 14px 16px;
        border: 1px solid #f3f3f3;
        border-radius: 2px;
        background-color: #ffffff;
        color: #000000;
        font-size: 13px;
        line-height: 16px;
        width: 100%;
        margin-bottom: 5px;

        &:focus,
        &:active,
        &:hover {
          box-shadow: none;
          outline: none;
        }
      }

      .form-group-delivery-note {
        label {
          color: #909090;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.175px;
        }
      }
      textarea {
        min-height: 140px;
      }
      .date-time-picker {
        p {
          color: #505050;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.15px;
        }
        .react-datepicker-wrapper {
          display: block;
        }
        .react-datepicker__close-icon::after {
          background-color: #fff;
          color: #000;
          font-size: 20px;
        }
      }
      .react-datepicker__input-container input {
        height: 70px;
        color: #505050;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.188px;
      }
    }
  }

  p {
    color: #909090;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.138px;
  }

  label {
    color: #909090;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15px;

    &.product-name {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.163px;
    }

    label {
      font-weight: 700;
      margin-right: 10px;
    }
  }

  .item-total-amount {
    color: #505050;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.163px;
  }

  .order-summary {
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-top: 1px solid #f3f3f3;
    height: calc(100vh - 67px);

    &.hasMultipleCustomers {
      height: calc(100vh - 103px);
    }

    @media (min-width: 1200px) {
      top: 67px;
      position: fixed;
      border-top: 0;

      &.hasMultipleCustomers {
        top: 103px;
        height: calc(100vh - 103px);
      }
    }

    .order-items {
      padding: 25px 0;
      overflow: auto;
      min-width: 400px;

      .order-cell {
        padding: 20px 20px;
        border-bottom: 1px solid #f3f3f3;
        max-width: 400px;

        .item-details {
          @media (min-width: 1200px) {
            margin-right: 80px;
          }
        }

        .img-wrapper {
          width: 56px;
          padding-top: 56px;
          min-width: 56px;
          position: relative;
          border-radius: 8px;
          overflow: hidden;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .img-overlay {
            transition: all ease 0.8s;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #f32d68;
            opacity: 0;

            .btn-remove {
              border-radius: 40px;
              background-color: #ffffff;
              display: flex;
              cursor: pointer;
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        .qty-wrapper {
          display: flex;
          padding: 5px 7px;
          align-items: center;
          margin-right: 5px;
          height: fit-content;
          border-radius: 2px;
          border: 1px solid #eaeaea;
          background: #fff;

          .qty-value {
            padding: 0 20px;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            line-height: 15px;
          }

          .qty {
            font-size: 18px;
            line-height: 1.1;
            color: #000000;
          }
        }
      }
    }
  }

  .place-container {
    border-top: 1px solid #f3f3f3;
    padding: 0 0 20px 0;

    .place-order-cta {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      background: #000000;
      padding: 12px 40px;
      border-color: #000000;
      width: 100%;
      color: #ffffff;
    }

    .origin {
      opacity: 0.5;
      text-decoration: line-through;
      margin-right: 6px;
    }

    label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
      margin-right: 15px;
    }
    p {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.175px;
    }
  }

  .mb-40 {
    margin-bottom: 40px;
  }
}

.checkout-background {
  background-color: #fff;
  transition: all ease 1s;
  width: 100%;
  position: relative;

  @media (min-width: 1200px) {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      background: #fafbf8;
    }

    .checkout-container {
      position: relative;
      z-index: 1;
    }
  }
}

.cart-timmer {
  padding: 10px 20px;
  border-bottom: 1px solid #f3f3f3;

  .timmer {
    font-weight: 700;
  }
}

.delivery-time {
  .form-check-input[type="checkbox"] {
    margin-top: 6px !important;

    &.form-check-input:checked {
      background-color: #000;
      border-color: #000;
    }
  }
}

// .cart-layout.active {
//   .checkout-background {
//     width: calc(100% - 300px);

//     @media screen and (max-width: 1200px) {
//       width: calc(100% - 270px);
//     }
//   }
// }

.gift-item-wrapper {
  background: #9b51e0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.175px;
  padding: 8px 20px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.order-summary-total {
  padding: 20px 20px;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.checkout-container {
  .cart-drawer {
    width: 100% !important;
    position: initial;

    .cart-wrapper {
      left: initial;
      right: initial;
      border-right: 1px solid #f3f3f3;
      position: initial;

      @media (max-width: 768px) {
        border-top: 1px solid #f3f3f3;

        .items-container {
          height: auto;
          overflow-y: auto;
        }
      }

      .cart-drawer-header {
        h4 {
          padding: 0;
          border: 0;
        }
        .btn-close {
          display: none;
        }
      }
    }

    .mobile-menu-header {
      display: none !important;
    }
  }
}

.order-confirm .product-order-success {
  max-width: 460px;
  margin: 0 auto;

  h5 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    color: #000;
  }
  .description p,
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    letter-spacing: 0.163px;
    color: #000;
  }
  .description label {
    font-size: 14px;
  }
}
