.item-container {
  display: flex;
  flex-flow: column;
  margin-bottom: 45px;

  .item-details {
    text-decoration: none;

    .origin {
      opacity: 0.5;
      text-decoration: line-through;
      margin-right: 6px;
    }
  }

  .img-wrapper {
    position: relative;
    padding: 20px 20px;
    margin-bottom: 8px;
    transition: all ease 0.8s;
    border: 1px solid #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    cursor: pointer;

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      max-width: 100px;
      height: 100px;
      transition: 0.3s all ease;
    }

    .product-tags {
      display: flex;
      align-items: center;
      position: absolute;
      top: 2px;
      left: 2px;
      flex-wrap: wrap;
      z-index: 1;

      span.badge {
        color: #fff;
        font-size: 11px;
        font-weight: 400;
        border-radius: 0;
        background: #219653 !important;
        padding: 5px 5px;
        margin-right: 3px;
        margin-bottom: 3px;

        &.new {
          background: #219653 !important;
        }
        &.fast-mover {
          background: #f2994a !important;
        }
        &.limited-supply {
          background: #eb5757 !important;
        }
      }
    }

    .thc-wrap {
      padding: 3px 4px;
      border-radius: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      background: #fafbf8;
      width: 100%;
      justify-content: center;

      span {
        color: #171718;
        font-size: 11px;
        font-weight: 400;
        text-align: center;
      }
    }

    .quick-actions {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 1px;
      background: rgba(135, 135, 135, 0.1);
      transition: all ease 0.8s;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0 8px;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border: none;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        color: #ffffff;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        transition: 0.3 all ease;

        &.active {
          color: #f32d68;
        }
      }
    }

    &:hover img {
      transform: scale(1.2);
    }
  }

  .details-wrapper {
    display: flex;
    flex-flow: column;
    z-index: 1;

    h4,
    p {
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.15px;
      margin-bottom: 5px;
    }
    .item-price {
      color: #707070;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.175px;
      margin-bottom: 5px;
    }

    .pack-price {
      color: #909090;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
      line-height: 1.1;
    }

    .title {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.188px;
      margin-bottom: 5px;
      display: inline-block;
      cursor: pointer;
      line-height: 1.1;

      .green-tg {
        margin-left: 5px;
        border-radius: 1px;
        width: 11px;
        height: 11px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1.1;

        &[data-phenotype="Indica"] {
          background-color: #511e84;
        }

        &[data-phenotype="Hybrid"] {
          background-color: #1fa824;
        }

        &[data-phenotype="Sativa"] {
          background-color: #cd001a;
        }

        &[data-phenotype="CBN"] {
          background-color: #72cddc;
        }

        &[data-phenotype="THCV"] {
          background-color: #74bf44;
        }

        &[data-phenotype="CBD"] {
          background-color: #9da7af;
        }

        span {
          font-weight: 600;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          color: #ffffff;
          line-height: 1.1;
        }
      }
    }

    .btn-atc {
      color: #505050;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.175px;
      margin-top: 8px;
      padding: 5px 20px;
      border-radius: 2px;
      border: 1px solid #b0b0b0;
      background: #fff;
      max-width: 170px;
      display: inline-block;
      line-height: 1.2;
      transition: all ease 0.5s;

      &:active {
        background-color: #000000;
        border-color: #000000;
        color: #ffffff;
      }

      svg {
        margin-right: 2px;
      }

      .plus-cta {
        margin-right: 0;
      }

      &:hover {
        color: #ffffff;
        background-color: #219653;
        border-color: #219653;
      }
    }

    .plus-minus {
      padding: 0;
      height: 30px;
      display: inline-flex;
      align-items: center;
      margin-top: 8px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.175px;
      border: 0;
      border-radius: 2px;
      background: #219653;
      width: initial;

      .control-icon {
        height: 30px;
        border-radius: 0;
        padding: 5px 10px;
        font-size: 20px;

        &:hover {
          background: #1e7e47;
        }
      }

      .quantity-block {
        height: 30px;
        width: 50px;
        font-size: 15px;
        font-weight: 500;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        color: #fff;
        line-height: 1;
      }
    }
  }

  &:hover {
    .quick-actions {
      opacity: 1;
    }
    .thc-wrap {
      background: #eeeeee;
      span {
        color: #000000;
      }
    }
    .title .product-name {
      text-decoration: underline;
    }
  }
}

.product-table {
  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .table-details,
  .table-header {
    padding: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background-color: #ffffff;

    &:nth-child(2n-1) {
      background-color: #fafbf8;
    }
  }

  .title-img {
    flex: 1;
  }

  .table-cell {
    width: 70px;
    margin-right: 30px;
    text-align: center;

    &[data-phenotype="Indica"] {
      color: #511e84;
    }

    &[data-phenotype="Hybrid"] {
      color: #1fa824;
    }

    &[data-phenotype="Sativa"] {
      color: #cd001a;
    }

    &[data-phenotype="CBN"] {
      color: #72cddc;
    }

    &[data-phenotype="THCV"] {
      color: #74bf44;
    }

    &[data-phenotype="CBD"] {
      color: #9da7af;
    }

    &.potency {
      width: 100px;
    }

    &.size {
      width: 80px;
    }

    &.add-cta {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .origin {
      opacity: 0.5;
      text-decoration: line-through;
      margin-right: 6px;
    }
  }

  .blank-img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  span {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }

  .add {
    padding: 0px 4px;
    line-height: 12px;
    border: 1px solid #000000;
    border-radius: 50px;
  }

  .product-info {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.188px;
    margin-bottom: 2px;
    display: inline-block;
    align-items: center;

    .green-tg {
      color: #ffffff;
      border: none;
      margin-left: 5px;

      max-height: 11px;
      border-radius: 1px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;

      span {
        color: #fff;
        font-size: 10px;
        line-height: 11px;
        text-align: center;
        width: 11px;
        height: 11px;
      }

      &[data-phenotype="Indica"] {
        background-color: #511e84;
      }

      &[data-phenotype="Hybrid"] {
        background-color: #1fa824;
      }

      &[data-phenotype="Sativa"] {
        background-color: #cd001a;
      }

      &[data-phenotype="CBN"] {
        background-color: #72cddc;
      }

      &[data-phenotype="THCV"] {
        background-color: #74bf44;
      }

      &[data-phenotype="CBD"] {
        background-color: #9da7af;
      }
    }
  }

  .table-header {
    span {
      font-weight: 600;
    }
  }
}

.quick-view-modal {
  .modal-dialog {
    max-width: 630px;
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none;
  }

  .product-details {
    .breadcrumb-item,
    .breadcrumb-item a {
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      color: #000000;

      &.active {
        color: #9da7af;
      }
    }

    .tag {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #000000;
      padding: 9.5px 20.5px;
      border: 1px solid #f3f3f3;
      border-radius: 60px;

      &.tag-green {
        color: #ffffff;
        border: none;
      }

      &[data-phenotype="Indica"] {
        background-color: #511e84;
      }

      &[data-phenotype="Hybrid"] {
        background-color: #1fa824;
      }

      &[data-phenotype="Sativa"] {
        background-color: #cd001a;
      }

      &[data-phenotype="CBN"] {
        background-color: #72cddc;
      }

      &[data-phenotype="THCV"] {
        background-color: #74bf44;
      }

      &[data-phenotype="CBD"] {
        background-color: #9da7af;
      }
    }

    .title {
      h1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.0125em;
        color: #000000;
      }

      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.0125em;
        color: #000000;
      }
    }

    .thc {
      p {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #9da7af;
      }

      h6 {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0125em;
        color: #000000;
      }
    }

    .price {
      margin-bottom: 54px;

      .price-total {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #9da7af;
      }

      .price-unit {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.0125em;
        color: #000000;
      }

      .price-amount {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #000000;
      }
    }

    .sold-case {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0125em;
      color: #9da7af;
    }

    .desc-table {
      border: 1px solid #f3f3f3;

      .description-limit label {
        text-align: right;
        padding-left: 30px;
      }

      label,
      label * {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #9da7af;

        * {
          color: #9da7af !important;
          font-weight: 400 !important;
        }
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #000000;
      }

      .description {
        padding: 16px 48px 16px 30px;

        &:nth-child(even) {
          background-color: #f4f5f2;
        }

        @media screen and (max-width: 767.98px) {
          padding: 16px 10px 16px 10px;
        }
      }
    }

    .qty-container {
      .qty-wrapper {
        display: flex;
        padding: 10px;
        border: 1px solid #f3f3f3;
        align-items: center;
        margin-right: 24px;
        width: fit-content;
        border-radius: 60px;

        .qty-value {
          padding: 0 12px;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          line-height: 15px;
        }

        .qty {
          font-size: 12px;
          padding: 0 5px;
          color: #9da7af;

          &.disable {
            cursor: default !important;
            opacity: 0.3;
          }
        }
      }

      .btn-atc {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
        background-color: #000000;
        padding: 10px 30.5px;
        border-color: #000000;
        border-radius: 60px;
        height: fit-content;

        &:hover {
          color: #000000;
          background-color: #ffffff;
        }
      }
    }

    .img-wrapper {
      position: relative;
      padding-top: 100%;
      border-radius: 30px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-slider {
      div {
        outline: none;
      }

      .slick-arrow {
        z-index: 2;
        padding: 6px;
        border-radius: 40px;
        width: auto;
        height: auto;
        background: rgba(0, 0, 0, 0.05);
        color: #ffffff;

        &::before {
          content: none;
        }
      }

      .slick-prev {
        left: 12px;
      }

      .slick-next {
        right: 12px;
      }

      .slick-dots {
        bottom: 12px;

        li {
          margin: 0 3.5px;
        }

        li,
        button,
        button::before {
          width: 7px;
          height: 7px;
          padding: 0;
        }

        button::before {
          content: "";
          background-color: #ffffff66;
          border-radius: 7px;
          opacity: 1;
        }

        li.slick-active {
          button::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &.order-confirm {
    .modal-dialog {
      max-width: 570px;
    }

    .subtext {
      opacity: 0.7;
      font-size: 14px;
    }

    .description {
      p,
      label {
        color: #000000;
        font-size: 16px;
        line-height: 20px;
      }

      label {
        color: #9da7af;
      }
    }

    .btn-primary {
      text-align: center;
      width: 370px;
      color: #ffffff;
      border-color: #000000;
      background: #000000;
      border-radius: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      padding: 14px;
    }
  }
}

.cart-action-btn {
  &:hover,
  &:active,
  &:focus {
    color: #219653;
  }
}
