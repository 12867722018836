.has-sidebar {
  position: relative;

  .products-wrapper {
    padding-top: 15px;

    @media (min-width: 992px) {
      .col-lg-2 {
        flex: 0 0 auto;
        width: 20%;
      }
    }
  }

  @media (min-width: 992px) {
    // &::before {
    //   content: "";
    //   display: block;
    //   height: 100%;
    //   width: 50%;
    //   background: #fafbf8;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: -1;
    // }
    .bg-white {
      background: #fff;
      min-height: 100vh;
    }
    .collection-container {
      // padding: 0;
      // margin-left: -10px;

      .collection-options {
        padding-left: 50px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #f3f3f3;
      }
      .products-wrapper {
        // padding-left: 50px;
        padding-top: 15px;
        padding-bottom: 20px;
      }
      .tags-wrapper {
        display: block;

        > div {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #f3f3f3;
          display: inline-flex;
          width: 100%;
        }
      }
    }
  }
}

.filter {
  display: flex;
  width: 305px;
  position: relative;
  padding-right: 0 !important;

  .filter-wrapper {
    position: sticky;
    top: 67px;
    width: 320px;
    background-color: #fafbf8;
    overflow: auto;
    height: calc(100vh - 68px);

    @media screen and (max-width: 1400px) {
      width: 280px;
    }

    &.hasMultipleCustomers {
      top: 98px;
      height: calc(100vh - 98px);
    }
  }

  @media screen and (max-width: 1200px) {
    width: 250px;

    .filter-wrapper {
      width: 249px;
    }
  }

  @media screen and (max-width: 991.98px) {
    position: fixed;
    background-color: #fafbf8;
    z-index: 12;
    width: 100%;
    right: 100%;
    height: 100%;
    top: 0;
    padding: 0 !important;
    transition: all ease 0.8s;

    .filter-wrapper {
      position: absolute;
      z-index: 12;
      width: 100%;
      top: 0 !important;
      height: 100%;
      padding: 0 20px;
    }

    &.active {
      right: 0;
    }
  }

  .mobile-menu-header {
    padding: 15px 20px 15px 0;
    border-bottom: 1px solid #f3f3f3;
    //background: #ffffff;

    @media screen and (max-width: 575.98px) {
      padding-right: 0;
    }

    h3 {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.175px;
      margin: 6px 0;
    }

    svg {
      color: #303030;
    }
  }

  .filter-accordion {
    border-bottom: 1px solid #f3f3f3;

    h4 {
      padding: 20px 20px 20px 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #303030;

      > svg {
        transform: rotate(180deg);
        transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 26px;
      //   right: 30px;
      //   width: 12px;
      //   border: 2px solid #303030;
      // }

      &.active > svg {
        transform: rotate(0deg);
      }
    }

    .form-check-input:checked {
      border-color: #303030;
      background-color: #303030;
    }

    .form-check-input:focus {
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
      border-color: #303030;
    }

    .filter-options {
      padding: 0 20px 10px;
      font-size: 13px;
      // max-height: 350px;
      // overflow: auto;
      h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: capitalize;
        transition: all ease 0.15s;

        &:hover {
          color: #00000077;
        }

        &.active {
          font-weight: 700;
        }
      }

      .form-check {
        min-height: initial;

        label {
          color: #101010;
          cursor: pointer;

          &:hover {
            color: #606060;
          }
        }

        label:hover + input[type="checkbox"] {
          background: red;
        }

        input[type="checkbox"]:checked ~ label {
          color: #303030;
          font-weight: 500;
        }
      }
    }
  }
}

.has-ar {
  .filter .filter-wrapper {
    top: 98px;
    height: calc(100vh - 98px);
  }
}

.has-ar.hasMultipleCustomers {
  .filter .filter-wrapper {
    top: calc(98px + 30px);
    height: calc(100vh - 98px - 30px);

    @media screen and (max-width: 992px) {
      height: 100vh !important;
    }
  }
}

.category-filter {
  max-height: 300px;
  overflow: auto;
}

.collection-fil {
  display: flex;
  flex-wrap: wrap;
}

.filter-item {
  color: #101010;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.163px;
  display: flex;
  padding: 2px 0 2px 2px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;

  svg {
    margin-left: 8px;
    margin-right: 6px;
    stroke-width: 2px;
    transition: 0.3s all ease;

    path {
      stroke: #101010;
    }
  }

  &:hover {
    color: #606060;

    svg {
      transform: rotate(90deg);
      path {
        stroke: #101010;
      }
    }
  }
}

.collection-container {
  padding: 5px 5px;
  flex: 1;

  @media screen and (max-width: 575.98px) {
    padding: 20px 10px;
  }

  .collection-options.d-lg-none {
    @media screen and (max-width: 992px) {
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 10px;
    }
  }

  .collection-tabs {
    h5 {
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      padding: 0 0 3px;
      margin: 0 24px 0 0;
      transition: all ease 0.15s;

      &:hover {
        color: #00000077;
      }

      &.active {
        border-bottom: 1px solid #303030;
        font-weight: 700;
      }

      @media screen and (max-width: 767.98px) {
        margin-bottom: 16px;
      }
    }
  }

  .collection-options {
    .btn-filter {
      background-color: #ffffff;
      border: 0;
      font-weight: 500;
      font-size: 14px;
      color: #303030;
      line-height: 18px;
      padding: 0;

      svg {
        transform: rotate(275deg);
        position: relative;
        top: -1px;
        right: -5px;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff;
        color: #303030;
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
      }

      h4 {
        font-weight: 700;
        font-size: 14px;
        text-transform: capitalize;
        line-height: 18px;
        color: #303030;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9da7af;
      }

      .dot {
        width: 3px;
        height: 3px;
        background-color: #9da7af;
        border-radius: 10px;
        margin: 0 8px;

        @media screen and (max-width: 992px) {
          display: none;
        }
      }
    }

    .filter-wrapper {
      .filter-dropdown {
        border: 0;
        padding: 5px 35px 5px 0;
        color: #9da7af;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #606060;
        letter-spacing: 0.163px;
        margin-right: 5px;

        &:hover,
        &:active,
        &:focus,
        &:focus-visible {
          box-shadow: none;
          border: 0;
          outline: 0;
        }
      }
    }
  }
}

.filter-label {
  color: #606060;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.163px;
  white-space: nowrap;
  margin-right: 5px;
}

.cart-layout .reset-button {
  color: #eb5757;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.163px;
  border-color: #eb5757;
  border-radius: 2px;
  border: 0;
  padding: 8.5px 15px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }

  @media screen and (max-width: 576px) {
    font-size: 11px;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: #eb5757;
  }
}

.filter-by-tags {
  padding-top: 20px;

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-flex;

      .btn-outline-dark {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.163px;
        border-radius: 2px;
        line-height: 1.15;

        &:not(.active) {
          color: rgba(0, 0, 0, 0.7);
          background: #fafbf8;
          border: 1px solid #d0d0d0;
        }
        &:hover:not(.active) {
          color: rgba(0, 0, 0, 0.9);
          border: 1px solid #303030;
        }
        &.active {
          &.new-release {
            background: #219653 !important;
            border-color: #219653 !important;
          }
          &.fast-movers {
            background: #f2994a !important;
            border-color: #f2994a !important;
          }
          &.limited-product {
            background: #eb5757 !important;
            border-color: #eb5757 !important;
          }
        }
      }
    }
  }
}

.dropdown.filter-dropdown {
  .dropdown-menu {
    min-width: 250px;
    padding-top: 0;
    padding-bottom: 0;
    border-color: #eaeaea;
    transform: translate3d(0px, 38px, 0px) !important;
    box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.02);
  }

  .filter-dropdown-item {
    border-bottom: 1px solid #eaeaea;
    .form-check {
      margin-bottom: 0;
      .form-check-input {
        margin-top: 17px;
        border-color: #303030;

        &:checked {
          background-color: #303030;
          border-color: #303030;
        }
      }
      label {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 13px;
      }
    }
    &:active,
    &:focus,
    &:hover {
      background-color: var(--bs-dropdown-link-hover-bg) !important;
    }
    &:last-child {
      border: 0;
    }
  }
}

.filter-bar {
  .btn-outline-dark,
  .btn-outline-secondary {
    padding: 8.5px 20px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.163px;
    border: 1px solid #eaeaea;
    color: #303030;

    @media screen and (max-width: 767px) {
      padding: 8.5px 15px;
      font-size: 12px;
    }

    @media screen and (max-width: 576px) {
      padding: 8.5px 9px;
      font-size: 11px;
    }
  }

  .btn-outline-secondary {
    font-weight: 500;
    &:hover:not(.active) {
      border: 1px solid #303030;
      background: #fff;
    }
    &.new-release {
      color: #219653 !important;
      &:hover {
        border-color: #219653 !important;
      }
    }
    &.fast-movers {
      color: #f2994a !important;
      &:hover {
        border-color: #f2994a !important;
      }
    }
    &.limited-product {
      color: #eb5757 !important;
      &:hover {
        border-color: #eb5757 !important;
      }
    }
    &.active {
      color: #fff !important;
      &.new-release {
        background: #219653 !important;
        border-color: #219653 !important;
      }
      &.fast-movers {
        background: #f2994a !important;
        border-color: #f2994a !important;
      }
      &.limited-product {
        background: #eb5757 !important;
        border-color: #eb5757 !important;
      }
    }
  }
}

.menu-category-name {
  background: #fafafa;
  border-bottom: 1px solid #eaeaea;
  font-size: 13px;
  font-weight: 500;
}

.filter-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

.filter-dropdown .dropdown-toggle:disabled {
  border-color: #a1a1a1;
  opacity: 0.3;
  cursor: not-allowed;
}

.page-header h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
}

.filter-items-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;

  li {
    margin-bottom: 10px;
    margin-right: 15px;

    @media screen and (max-width: 767px) {
      margin-right: 12px;
    }
  }
}

.list-toggle.d-flex {
  display: inline-flex !important;
  margin-top: 5px;
  @media screen and (min-width: 576px) {
    float: right;
    margin-top: 0;
  }
  @media screen and (max-width: 576px) {
    position: absolute;
    top: -45px;
    right: 10px;
  }
}

.filter-dropdown .dropdown-toggle {
  &::after {
    height: 10px;
    width: 10px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 0;
    vertical-align: middle;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjODA4MDgwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  }
  &.show,
  &:hover,
  &:focus,
  &:active {
    border-color: #000 !important;
    background: #fff !important;
    color: #000 !important;
  }
  &.show::after {
    transform: rotate(180deg);
  }
}

.filter-bar {
  position: sticky;
  top: 67px;
  left: 0;
  background: #fff;
  z-index: 9;
  padding: 10px 0 0;
}

.has-ar {
  .filter-bar {
    top: 98px;
  }
  &.hasMultipleCustomers .filter-bar {
    top: 129px;
  }
}

.dropdown:not(.disabled):hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
