.featured-images-wrapper {
  padding: 50px 0;
  background: #fafbf8;

  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 0 15px;
  }

  .slick-slide div {
    margin: 0 10px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      margin: 0 5px;
    }

    a {
      border-radius: 5px;
      overflow: hidden;
    }

    img {
      width: 100%;
      max-height: 290px;
      object-fit: cover;
      object-position: center;
      transition: 0.3s all ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.product-categories-wrapper {
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}
.bundle-products {
  margin-bottom: 105px;

  @media screen and (max-width: 575px) {
    margin-bottom: 30px;
  }

  h5 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin-bottom: 20px;
  }

  .bundle-product-block {
    margin-bottom: 10px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      margin-bottom: 25px;
    }

    .product-image {
      position: relative;
      border-radius: 1px;
      border: 1px solid #f1f1f1;
      background: #fff;
      position: relative;
      padding: 20px 10px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      overflow: hidden;

      &:after,
      &:before {
        content: "";
        display: block;
        border: 1px solid #f1f1f1;
        position: absolute;
        bottom: -5px;
        left: 2.5%;
        width: 95%;
        height: 5px;
        border-top: 0;
      }

      &:before {
        bottom: -10px;
        left: 5%;
        width: 90%;
      }

      .product-tag {
        color: #fff;
        font-size: 11px;
        font-weight: 400;
        background: #219653;
        position: absolute;
        top: 2px;
        left: 2px;
        padding: 4px 10px;
        line-height: 1.1;
      }

      img {
        transition: 0.3s all ease-in-out;
      }
    }

    .product-name {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.175px;
      margin-bottom: 1px;
    }

    .offered-deal {
      color: #505050;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
      text-decoration-line: underline;
      margin-bottom: 5px;
    }
    .shop-link-btn {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.2px;
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
    &:hover img {
      transform: scale(1.2);
    }
  }
}

.new-release-products {
  margin-bottom: 115px;

  @media screen and (max-width: 575px) {
    margin-bottom: 30px;
  }

  .col-xl-3.col-md-6 {
    @media screen and (max-width: 1199px) {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 5px;
    }
  }

  h5 {
    font-size: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }

    @media screen and (max-width: 360px) {
      font-size: 14px;
    }

    span {
      color: #808080;
      font-weight: 400;
      padding: 2px;
      cursor: pointer;
      margin-left: 35px;
      position: relative;

      @media screen and (max-width: 575px) {
        margin-left: 15px;
      }

      @media screen and (max-width: 360px) {
        margin-left: 5px;
      }

      &:first-child {
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
      }

      &.active {
        color: #000;
        font-weight: 500;

        &::after {
          display: block;
          content: "";
          height: 2px;
          background-color: #000;
          width: 100%;
          position: absolute;
          bottom: -9px;
          left: 0;
        }
      }
      &:hover,
      &:focus,
      &:active {
        color: #000;
      }

      &.last-child {
        float: right;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.188px;

        @media screen and (max-width: 575px) {
          display: none;
        }

        a {
          color: #111;
          text-decoration: none;

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }

        svg {
          display: inline-block;
          width: 17px;
          vertical-align: bottom;
          color: #808080;
        }
      }
    }
  }
}

.product-categories-wrapper {
  position: relative;
  padding: 20px 0;

  &:after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background: #fafbf8;
  }
  .product-categories {
    list-style: none;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    background: #fff;
    position: relative;
    z-index: 1;

    li {
      cursor: pointer;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 20px;
      width: 12.5%;
      color: #101010;

      @media screen and (max-width: 1199px) {
        padding: 25px 12px;
      }

      @media screen and (max-width: 992px) {
        padding: 25px 8px;
        width: auto;
      }

      @media screen and (max-width: 768px) {
        padding: 15px 20px;
      }

      @media screen and (max-width: 480px) {
        padding: 10px 8px;
        font-size: 15px;
      }

      svg {
        position: relative;
        transition: 0.3s all ease-in-out;
        height: 24px;

        path {
          transition: 0.3s all ease-in-out;
        }
      }

      &:hover {
        svg {
          transform: translateY(-5px);
        }

        color: #606060;
      }
    }
  }
}

// .product-block {
//   @media screen and (max-width: 992px) {
//     margin-bottom: 20px;
//   }
//   .product-image {
//     border-radius: 5px;
//     border: 1px solid #f1f1f1;
//     background: #fafafa;
//     padding: 20px;
//     display: flex;
//     align-content: center;
//     justify-content: center;
//     margin-bottom: 10px;
//     cursor: pointer;

//     img {
//       transition: 0.3s all ease-in-out;
//     }
//   }
//   .product-name {
//     color: #000;
//     font-size: 14px;
//     font-weight: 500;
//     letter-spacing: 0.175px;
//   }
//   &:hover {
//     .product-image img {
//       transform: scale(1.2);
//     }
//   }
// }

.new-item {
  display: flex;
  align-items: center;
  padding: 20px 5px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  transition: all ease 0.8s;
  text-decoration: none;
  height: 100%;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .new-product-image {
    background: #fff;
    margin-right: 8px;
    padding: 2px 0px 2px 0px;
    border-right: 1px solid #f1f1f1;
    .img-wrapper {
      width: 54px;
      padding-top: 52px;
      position: relative;
      overflow: hidden;
      height: -moz-fit-content;
      height: fit-content;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .new-item-wrapper {
    flex: 1 1;
    display: flex;
    flex-flow: column;
    h6 {
      color: #808080;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.138px;
      margin-bottom: 4px;
    }
    label {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.163px;
      display: flex;
      margin-bottom: 8px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      line-height: 1;
    }
    .green-tg {
      margin: 0 0 0 5px;
      margin-left: 5px;
      font-size: 10px;
      text-align: center;
      width: 10px;
      max-height: 11px;
      border-radius: 1px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 5px 3px;

      &[data-phenotype="Indica"] {
        background-color: #511e84;
      }

      &[data-phenotype="Hybrid"] {
        background-color: #1fa824;
      }

      &[data-phenotype="Sativa"] {
        background-color: #cd001a;
      }

      &[data-phenotype="CBN"] {
        background-color: #72cddc;
      }

      &[data-phenotype="THCV"] {
        background-color: #74bf44;
      }

      &[data-phenotype="CBD"] {
        background-color: #9da7af;
      }
    }

    .product-price-info {
      .price-per-unit {
        font-weight: 700;
        letter-spacing: 0.15px;
        margin-right: 5px;
        color: #909090;
        font-size: 12px;
        margin-bottom: 0;
      }
      .offered-price-info {
        font-weight: 400;
        margin-right: 0;
        color: #909090;
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    &:hover label .product-name {
      text-decoration: underline;
    }
  }
}

.spotlight-product-wrapper {
  padding: 70px 0;
  background: #fafafa;
  color: #000;

  @media screen and (max-width: 575px) {
    padding: 30px 0;
    margin-bottom: 40px;
  }

  .spotlight-product-block {
    border-radius: 5px;

    .product-image {
      position: relative;
      padding: 15px 20px;
      display: inline-block;

      @media screen and (max-width: 575px) {
        margin-bottom: 20px;
      }

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: rgb(242, 242, 242);
        background: linear-gradient(200deg, rgba(242, 242, 242, 1) 55%, rgba(255, 255, 255, 1) 100%);
        width: 33%;
        height: 40%;
        z-index: 1;
      }

      &:after {
        top: initial;
        right: initial;
        bottom: 0;
        left: 0;
        background: rgb(242, 242, 242);
        background: linear-gradient(0deg, rgba(242, 242, 242, 1) 55%, rgba(255, 255, 255, 1) 100%);
      }

      img {
        border-radius: 5px;
        position: relative;
        z-index: 2;
      }
    }
    .product-detail {
      max-width: 370px;
    }
    .product-title {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.25px;
      margin-bottom: 15px;
    }
    .product-name {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      margin-bottom: 5px;
    }
    .product-desp {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      ul {
        list-style: none;
        margin: 0 0 20px 0;
        padding: 0;
        li {
          padding: 10px 0;
          border-bottom: 1px solid #f1f1f1;
        }
      }
    }
    .product-action {
      .btn {
        border-radius: 2px;
        // backdrop-filter: blur(2.5px);
        color: #000;
        font-size: 14px;
        font-weight: 500;
        // line-height: 160%; /* 22.4px */
        letter-spacing: 0.175px;
        padding: 8px 20px;
        // display: inline-flex;
        // align-items: center;
        // white-space: nowrap;

        svg {
          height: 18px;
          width: 18px;
          margin-left: 80px;
        }

        // &:hover,
        // &:active,
        // &:focus {
        //   background: #000;
        //   color: #fff;
        //   border-color: #000;
        // }
      }
    }
  }
}

.brands-wrapper {
  margin-bottom: 40px;
  overflow: hidden;

  @media screen and (max-width: 575px) {
    margin-left: 0;
    margin-right: 0;
  }

  .title-wrapper h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
  }
  .slick-list {
    overflow: visible;
    margin: 0 -23px;
  }

  .img-brand {
    position: relative;
    padding: 0 22px;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease;
    padding-top: 20px;

    @media screen and (max-width: 480px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      transform: translate3d(0, -8px, 0);
      border-radius: 24px;
    }

    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #f3f3f3;
    }
  }

  .slick-slide {
    padding-bottom: 40px;
  }

  .slick-slider .slick-arrow {
    &.slick-next {
      right: -8px;
    }

    &.slick-prev {
      left: -8px;
    }
  }
}

// .dashboard-container {
//   h3 {
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 25px;
//     letter-spacing: 0.0125em;
//     color: #000000;
//     margin-bottom: 8px;
//   }

//   h6 {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 18px;
//     letter-spacing: 0.0125em;
//     color: #9da7af;
//   }

//   .link {
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 20px;
//     text-decoration: none;
//     color: #000000;
//   }

//   .banner-slider {
//     margin: 0 -5px;

//     .banner-img {
//       padding: 0 5px;
//     }
//   }

//   .title-wrapper {
//     padding: 0 17px;
//   }

//   .slick-slider {
//     div {
//       outline: none;
//     }

//     .slick-arrow {
//       z-index: 2;
//       padding: 12px;
//       border-radius: 40px;
//       width: auto;
//       height: auto;
//       background-color: #ffffff;
//       box-shadow: 0px 8px 8px rgba(64, 66, 78, 0.1);
//       color: black;

//       &::before {
//         content: none;
//       }
//     }

//     .slick-dots {
//       bottom: 24px;

//       li {
//         margin: 0 3.5px;
//       }

//       li,
//       button,
//       button::before {
//         width: 7px;
//         height: 7px;
//         padding: 0;
//       }

//       button::before {
//         content: "";
//         background-color: #ffffff66;
//         border-radius: 7px;
//         opacity: 1;
//       }

//       li.slick-active {
//         width: 16px;

//         button,
//         button::before {
//           width: 16px;
//         }

//         button::before {
//           background-color: #ffffff;
//         }
//       }
//     }
//   }

//   .banner-wrapper {
//     margin-bottom: 40px;

//     img {
//       width: 100%;
//       height: auto;
//       border-radius: 24px;
//     }
//   }

//   .category-wrapper {
//     .title-icon {
//       display: inline-flex;
//       width: 24px;
//       height: 24px;
//       background: rgba(212, 192, 183, 1);
//       align-items: center;
//       justify-content: center;
//       border-radius: 4px;
//       margin-right: 10px;
//     }

//     .fast-icon {
//       display: inline-flex;
//       width: 24px;
//       height: 24px;
//       background: rgba(205, 219, 186, 1);
//       align-items: center;
//       justify-content: center;
//       border-radius: 4px;
//       margin-right: 10px;
//     }

//     .category-link {
//       color: #000000;
//     }

//     .category-item {
//       transform: translate3d(0, 0, 0);
//       transition: all 0.3s ease;
//       padding-bottom: 20px;
//       margin-bottom: 20px;

//       .category-details {
//         flex: 1;

//         h4 {
//           font-size: 14px;
//           line-height: 17px;
//         }

//         h6 {
//           font-size: 12px;
//           line-height: 15px;
//         }
//       }

//       &:hover {
//         transform: translate3d(0, -16px, 0);
//         box-shadow: 0px 24px 48px rgb(0 0 0 / 8%);
//         border-radius: 24px;
//       }
//     }

//     img {
//       width: 100%;
//       height: auto;
//       border-radius: 24px;
//     }

//     h4 {
//       font-weight: 500;
//       font-size: 16px;
//       line-height: 20px;
//       letter-spacing: 0.0125em;
//       margin-bottom: 8px;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     .brands-wrapper {
//       margin-left: -8px;
//       margin-right: -8px;

//       .img-brand {
//         padding: 0 8px;
//       }
//     }

//     .title-wrapper {
//       padding: 0 8px;
//     }

//     .banner-wrapper {
//       img {
//         border-radius: 0;
//       }
//     }
//   }
// }
