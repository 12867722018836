.cart-layout.active {
  .product-container,
  .dashboard-container {
    width: calc(100% - 300px);
  }
}
.dashboard-container {
  transition: all ease 1s;
}

.last-purchase {
  opacity: 0.5;
  font-size: 12px;
  margin-top: 8px;
  font-style: italic;
}

.product-container {
  transition: all ease 1s;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 67px);
  flex: 1;
  position: relative;

  &.hasMultipleCustomers {
    min-height: calc(100vh - 103px);
  }

  .sub-title {
    color: #808080;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.163px;
  }

  .similar-items {
    padding: 40px 30px;
    margin-right: -12px;

    @media screen and (max-width: 768px) {
      border: 0;
    }

    h5 {
      color: #505050;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.15px;
    }

    .similar-wrapper {
      margin-left: -10px;
      margin-right: -10px;
    }

    .similar-cell {
      border: 1px solid #f3f3f3;
      background: #fafbf8;
      padding: 15px;
      margin: 10px;

      .img-wrapper {
        width: 70px;
        padding-top: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        background: #fff;
        margin-right: 10px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      a {
        text-decoration: none;
        h4 {
          color: #909090;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0.138px;
          text-decoration: none;
        }

        label {
          color: #000;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.163px;
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          cursor: pointer;

          span {
            &.green-tg {
              margin: 2px 0 0 5px;
              font-size: 10px;
              text-align: center;
              width: 10px;
              max-height: 11px;
              border-radius: 1px;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
            }

            &[data-phenotype="Indica"] {
              background-color: #511e84;
            }

            &[data-phenotype="Hybrid"] {
              background-color: #1fa824;
            }

            &[data-phenotype="Sativa"] {
              background-color: #cd001a;
            }

            &[data-phenotype="CBN"] {
              background-color: #72cddc;
            }

            &[data-phenotype="THCV"] {
              background-color: #74bf44;
            }

            &[data-phenotype="CBD"] {
              background-color: #9da7af;
            }
          }
        }

        p {
          color: #909090;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.15px;

          span {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.15px;
          }
        }
      }
      &:hover label {
        text-decoration: underline;
      }
    }

    .similar-wrapper {
      // @media screen and (max-width: 767.98px) {
      //   margin: 0 -12px;
      //   .similar-cell {
      //     width: 50%;
      //     padding: 0 12px;
      //     margin-right: 0;

      //     &:nth-child(3) {
      //       display: none;
      //     }
      //   }
      // }
    }

    .plus-cart {
      cursor: pointer;
      padding: 3px 6px;
      border-radius: 20px;
      border: 1px solid #000000;
      line-height: 16px;
    }
  }

  .product-details {
    .breadcrumb-item,
    .breadcrumb-item a {
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      color: #000000;

      &.active {
        color: #9da7af;
      }
    }

    .tag {
      color: #171718;
      font-size: 11px;
      font-weight: 400;
      padding: 1px 12px;
      border: 1px solid #f3f3f3;
      border-radius: 2px;
      background: #f1f1f1;

      &.tag-green {
        color: #ffffff;
        border: none;
      }

      &[data-phenotype="Indica"] {
        background-color: #511e84;
      }

      &[data-phenotype="Hybrid"] {
        background-color: #1fa824;
      }

      &[data-phenotype="Sativa"] {
        background-color: #cd001a;
      }

      &[data-phenotype="CBN"] {
        background-color: #72cddc;
      }

      &[data-phenotype="THCV"] {
        background-color: #74bf44;
      }

      &[data-phenotype="CBD"] {
        background-color: #9da7af;
      }
    }

    .title {
      h1 {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.25px;
      }

      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.0125em;
        color: #000000;
      }
    }

    .thc {
      p {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #9da7af;
      }

      h6 {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0125em;
        color: #000000;
      }
    }

    .price-info-block {
      border-radius: 5px;
      border: 1px solid #f1f1f1;
      background: #fafafa;
      display: inline-flex;
      align-items: baseline;
      padding: 10px 20px;
      width: 100%;
    }

    .price {
      margin-bottom: 40px;

      .price-total,
      .price-amount {
        color: #505050;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.163px;
      }

      .price-unit {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.25px;
        margin-right: 6px;
      }
    }

    .sold-case {
      color: #505050;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
      text-align: left;
    }

    .desc-table {
      .description {
        p {
          margin-bottom: 5px;
          color: #505050;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.15px;
        }
        label {
          text-align: left;
          margin-bottom: 20px;
          color: #505050;
          font-size: 13px !important;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: 0.163px;
          margin-bottom: 20px !important;
        }
      }

      label,
      label * {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.0125em;
        color: #9da7af;

        * {
          color: #505050;
          font-size: 13px !important;
          font-weight: 400;
          line-height: 160%; /* 20.8px */
          letter-spacing: 0.163px;
          margin-bottom: 20px !important;
        }
      }

      p {
        color: #505050;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.15px;
      }
    }

    .qty-container {
      .qty-wrapper {
        display: flex;
        align-items: center;
        margin-right: 24px;
        width: fit-content;
        border-radius: 60px;

        .qty-value {
          color: #505050;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0.163px;
          padding: 0 12px;
          border-radius: 5px;
          border: 1px solid #eaeaea;
          background: #fff;
          width: 50px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          margin-left: 10px;
        }

        .qty {
          color: #505050;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.163px;
          padding: 0 5px;
          border-radius: 5px;
          border: 1px solid #eaeaea;
          background: #fff;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.disable {
            cursor: default !important;
            opacity: 0.3;
          }
        }
      }

      .btn-atc {
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.2px;
        background-color: #219653;
        padding: 15px 23px;
        border-color: #219653;
        border-radius: 2px;
        height: fit-content;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;

        &:hover,
        &:active,
        &:focus {
          background-color: #1e7e47;
          border-color: #1e7e47;
        }
      }
    }

    .img-wrapper {
      position: relative;
      padding-top: 100%;
      border-radius: 30px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-slider {
      div {
        outline: none;
      }

      .slick-arrow {
        z-index: 2;
        padding: 2px;
        border-radius: 40px;
        width: auto;
        height: auto;
        background: #fff;
        color: #808080;
        border: 1px solid #fafafa;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

        &::before {
          content: none;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }

      .slick-prev {
        left: -30px;

        @media screen and (max-width: 767.98px) {
          left: -16px;
        }
      }

      .slick-next {
        right: -30px;

        @media screen and (max-width: 767.98px) {
          right: -16px;
        }
      }

      .slick-dots {
        bottom: 24px;

        li {
          margin: 0 3.5px;
        }

        li,
        button,
        button::before {
          width: 7px;
          height: 7px;
          padding: 0;
        }

        button::before {
          content: "";
          background-color: #ffffff66;
          border-radius: 7px;
          opacity: 1;
        }

        li.slick-active {
          button::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

.resources-buttons {
  margin-bottom: 40px;

  .btn-outline-primary {
    border-radius: 4px;
    border: 1px solid #505050;
    background: #fff;
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.175px;
    padding: 13px 5px;
    width: 100%;

    &:hover,
    &:hover:active,
    &:active,
    &:focus {
      border-color: #000;
      background: #000;
      color: #fff;
    }
  }
}

.product-quantity-in-stock {
  margin-bottom: 35px;
  p {
    color: #505050;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.163px;

    &.stock {
      font-weight: 600;
    }
  }
}
.product-detail-desp-box {
  padding-top: 45px;
  padding-left: 50px;
  max-width: 400px;
  border-left: 1px solid #f1f1f1;

  @media screen and (max-width: 768px) {
    padding-top: 0;
    padding-left: 10px;
    border-left: 0;
  }
}
.main-product-image {
  padding-bottom: 60px;
  padding-top: 60px;
  background: #fafafa;
  margin: 0 -13px 0px;

  @media screen and (max-width: 767.98px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .slick-slider {
    max-width: 360px;
    margin: 0 auto;

    @media screen and (max-width: 767.98px) {
      max-width: 240px;
    }
  }
}

.product-action-button-wrapper span.plus-minus {
  border: 1px solid #219653;
  background: #219653;
  height: 48px;
  width: 100%;

  span {
    color: #fff;
    font-size: 20px;
    padding: 9px 35px;

    &.quantity-block {
      color: #fff;
      font-size: 16px;
    }

    &.control-icon {
      &:hover,
      &:active,
      &focus {
        background: #1e7e47;
      }
    }
  }
}
