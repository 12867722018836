.newsletter-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .newsletter-wrapper {
    padding: 40px 0 40px;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }
  }

  .form-group {
    input,
    textarea {
      padding: 14px 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      background-color: #ffffff;
      color: #000000;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 20px;
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
        border-color: #000000;
      }
    }
  }

  label {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
  }

  .btn {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: #000000;
    border-radius: 50px;
    padding: 10.5px 15px;
    width: 80px;
  }

  .btn-primary {
    border: 1px solid #000000;
    background-color: #fafbf8;
    width: initial;

    &:hover {
      background-color: #000000;
      color: #f3f3f3;
      border-color: #000000;
    }
  }

  .btn-secondary {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
  }
}

.bg-gray {
  background-color: #fafbf8;
  transition: all ease 1s;
  width: 100%;
}

.newsletter-container {
  .accordion-button:not(.collapsed) {
    color: #222222;
    background-color: #f4f5f2;
    // box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #f0f0f0;
  }
  .accordion-button:focus {
    border: 0;
    box-shadow: none;
  }

  .banner-form {
    border-bottom: 1px solid #edebeb;
    &.noBorder {
      border-bottom: 0;
    }
    h6 {
      margin-bottom: 5px;
      font-size: 15px;
    }

    padding: 15px 25px;
    .form-group input {
      margin-bottom: 5px;
    }
  }
}
