.account-switcher-container {
  background: #fafafa;
  border: 1px solid #f1f1f1;
  height: 31px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;

  .dropdown {
    text-align: center;

    a {
      text-decoration: none;
      color: #202020;
      font-weight: 400;
      font-size: 12px;

      span {
        color: #3661d0;
        font-weight: 500;
      }
    }
  }
  .dropdown-menu {
    width: 100%;
    min-width: 300px;
    border: 1px solid#EAEAEA;
    box-shadow: 0px 20px 30px rgba(112, 118, 101, 0.1);
    border-radius: 0;
    padding: 0;
    margin-top: 6px;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      height: 20px;
      width: 20px;
      z-index: 9;
      top: -20px;
      right: 50%;
      border-bottom: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 20px;
      width: 20px;
      z-index: 8;
      top: -21px;
      right: 50%;
      border-bottom: 10px solid #eee;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .account-lists a {
    font-size: 13px;
    font-weight: 500;
    padding: 20px 30px;

    &:not(:last-child) {
      border-bottom: 1px solid#EAEAEA;
    }

    &:active,
    &:focus,
    &:hover,
    &:active:hover {
      background: #f1f1f1;
    }

    div {
      color: #404040;
      font-weight: 400;
    }
  }
}

.account-lists {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
