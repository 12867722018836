.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  li {
    @extend .text-style;
    a {
      white-space: nowrap;
      @extend .text-style;

      &:hover {
        color: #606060;
      }
    }
  }

  li:not(:last-child) {
    margin-right: 10px;
  }
}

.main-footer {
  border-top: #f1f1f1 1px solid;
  padding: 40px 0;
  position: relative;
  z-index: 1;
  background: #fafbf8;
}

.text-style {
  color: #101010;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
