.details-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .avatar img {
    width: 70px;
    height: 70px;
    border-radius: 70px;
  }

  .btn {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: #000000;
    border-radius: 5px;
    padding: 10.5px 15px;
    width: 80px;
  }

  .details-wrapper {
    padding: 100px 0 60px;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;

    label {
      font-size: 12px;
      line-height: 15px;
      color: #9da7af;
      margin-bottom: 8px;
    }

    .details-rep {
      padding: 24px 30px 42px;
      background-color: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      margin-bottom: 40px;
    }

    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      color: #9da7af;
    }

    .update-info span {
      color: #000000;
    }

    .btn-second {
      border: 1px solid #f3f3f3;
    }

    input {
      padding: 14px 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      background-color: transparent;
      color: #000000;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 30px;
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
      }
    }

    .address-info {
      padding: 14px 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      margin-bottom: 30px;

      p {
        color: #000000;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .form-group {
      label {
        color: #000000;
      }
    }

    .btn-secondary {
      border: 1px solid #000000;
      background-color: #fafbf8;
    }

    .btn-primary {
      background-color: #000000;
      color: #ffffff;
      border-color: #000000;
    }
  }
}

.bg-gray {
  background-color: #fafbf8;
  transition: all ease 1s;
  width: 100%;
}
// .cart-layout.active {
//   .bg-gray {
//     width: calc(100% - 300px);

//     @media screen and (max-width: 1200px) {
//       width: calc(100% - 270px);
//     }
//   }
// }
