.favorites-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .favorites-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    .dot {
      width: 3px;
      height: 3px;
      border-radius: 10px;
      background-color: #9DA7AF;
    }

    .count {
      font-size: 14px;
      line-height: 18px;
      color: #9DA7AF;
    }

    .input-search {
      position: relative;

      label {
        margin-bottom: 0;
        position: absolute;
        top: 6px;
        left: 16px;
      }

      input {
        font-size: 14px;
        line-height: 18px;
        border-radius: 50px;
        background-color: transparent;
        padding: 9px 20px 9px 46px;
        border: 1px solid #F3F3F3;

        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
        }
      }
    }

    .filter-dropdown {
      border: 1px solid #F3F3F3;
      border-radius: 50px;
      padding: 9px 34px 9px 14px;
      color: #9DA7AF;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background-color: transparent;
      width: 190px;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
      }
    }
  }
}