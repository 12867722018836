.privacy-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .privacy-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }
  }
}
