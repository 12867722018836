.delivery-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .cutoff-wrapper {
    color: #000000;
    cursor: pointer;
    width: fit-content;
  }

  .delivery-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30.24px;
      color: #000000;
    }

    .title-wrapper {
      button {
        background: #000000;
        border-radius: 50px;
        border-color: #000000;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        padding: 10.5px 21.5px;
      }
    }

    .table-header, .table-details {
      display: flex;
    }

    .table-header {
      background-color: #000000;
      color: #FFFFFF;
      font-weight: 600;
      border-radius: 10px 10px 0 0;
    }

    .table-details {
      font-weight: 600;

      &:nth-child(2n-1) {
        background-color: #F4F5F2;
      }
    }

    .table-cell {
      text-align: center;
      font-size: 12px;
      line-height: 140%;
      padding: 15px 25px;

      &.cell-zone {
        width: 15%;
      }

      &.cell-time {
        width: 25%;
      }

      &.cell-edit {
        width: 15%;
      }

      &.cell-available {
        flex: 1;

        .all {
          color: #1F9A41;
        }
      }
    }
  }
}

.delivery-zone {
  &.quick-view-modal .modal-dialog {
    max-width: 350px;
  }

  .form-control {
    padding: 14px 16px;
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 30px;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
      border-color: #000000;
    }
  }

  .form-check-input:checked {
    border-color: #000000;
    background-color: #000000;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    border-color: #000000;
  }

  label {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
  }

  .btn {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    border-radius: 50px;
    padding: 10.5px 15px;
  }

  .btn-primary {
    border: 1px solid #000000;
    background-color: #000000;
    color: #FFFFFF;

    &:hover {
      background-color: #FFFFFF;
      color: #F3F3F3;
      border-color: #000000;
    }
  }

  .btn-second {
    background-color: #FFFFFF;
    color: #000000;
    border-color: #000000;

    &:hover {
      background-color: #000000;
      color: #FFFFFF;
      border-color: #000000;
    }
  }
}
