.navbar-container {
  width: 270px;
  padding: 40px 0;
  border-right: 1px solid #f3f3f3;
  height: 100%;
  min-height: calc(100vh - 165px);

  &.hasMultipleCustomers {
    min-height: calc(100vh - 200px);
  }

  @media screen and (max-width: 991.98px) {
    display: none;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    line-height: 30px;
    margin-bottom: 30px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0125em;
      color: #101010;
      padding: 16px 30px;

      &.active,
      &:hover {
        background-color: #f4f5f2;
        border-right: 1px solid #000000;
        color: #606060;
      }

      &.logout {
        color: #f32d68;
      }
    }
  }
}
