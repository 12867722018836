.order-container {
  height: 100%;
  min-height: calc(100vh - 67px);

  .download-invoice {
    text-decoration: none;
  }

  .btn-rebuild {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    background-color: #219653;
    padding: 7px 22px;
    border-color: #219653;
    border-radius: 1px;
    height: fit-content;

    &:hover {
      color: #147749;
      background-color: #ffffff;
      border-color: #147749;

      span {
        color: #219653;
      }
    }
  }

  .order-wrapper {
    padding: 30px;
    flex: 1;

    @media screen and (max-width: 575.98px) {
      padding: 30px 0;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }

    .btn-invoice {
      padding: 9px 35px;
      border: 1px solid #f3f3f3;
      border-radius: 50px;

      svg {
        color: #000000;
      }

      span {
        color: #9da7af;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .title-wrapper {
      margin-bottom: 38px;
    }

    h6 {
      color: #909090;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.138px;

      .green-tg {
        margin-left: 5px;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        width: 10px;
        max-height: 11px;
        border-radius: 1px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;

        &[data-phenotype="Indica"] {
          background-color: #511e84;
        }

        &[data-phenotype="Hybrid"] {
          background-color: #1fa824;
        }

        &[data-phenotype="Sativa"] {
          background-color: #cd001a;
        }

        &[data-phenotype="CBN"] {
          background-color: #72cddc;
        }

        &[data-phenotype="THCV"] {
          background-color: #74bf44;
        }

        &[data-phenotype="CBD"] {
          background-color: #9da7af;
        }
      }
    }

    p {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.163px;
      text-transform: capitalize;
      color: #000000;
      margin-top: 10px;
    }

    [data-status="on hold"] {
      color: #add8e6;
    }

    [data-status="open"] {
      color: #fee12b;
    }

    [data-status="shipping"] {
      color: #00008b;
    }

    [data-status="completed"] {
      color: #1f9a41;
    }

    [data-status="canceled"] {
      color: #f32d68;
    }

    .order-date {
      margin-right: 53px;
    }

    .order-items {
      border: 1px solid #f3f3f3;
    }

    .order-item {
      padding: 15px;
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
      }

      h6 {
        color: #000;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.163px;
      }

      p {
        color: #909090;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0.138px;
        margin-bottom: 0 !important;
        line-height: 1.2;
      }
    }

    .total-amount {
      border-bottom: 1px solid #f3f3f3;
    }

    .divider {
      color: #f3f3f3;
      opacity: 1;
      margin: 24px 0 20px;
    }

    .font-light {
      font-weight: 400;
    }

    .order-info {
      padding-top: 20px;
      margin-bottom: 30px;
    }

    .delivery-info {
      margin-right: 100px;
    }

    .img-wrapper {
      // background: #fff;
      border-radius: 5px;
      padding: 15px 5px;
      img {
        width: 56px;
        height: 56px;
      }
    }
  }
}

.order-summary-info {
  border: 1px solid #f3f3f3;
  margin-bottom: 30px;
  > div {
    flex: 1;
    padding: 20px 30px;
    text-align: left;
    &:not(:last-child) {
      border-right: 1px solid #f3f3f3;
    }
  }
}

.order-rebuild-btn-wrapper {
  margin-bottom: 10px;
  label {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.188px;
  }
}

.product-added-success-modal {
  max-width: 330px;
  margin: 25px auto 50px;
  .body-content {
    padding: 0 10px;
    margin-bottom: 15px;
  }
  .icon-box {
    margin-bottom: 20px;
  }
  h5 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
  }
  p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    letter-spacing: 0.163px;
    margin-bottom: 25px;
  }
  .aware-button {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.175px;
    padding: 13px 10px;
    border-radius: 2px;
  }
}

.outofstockProductsModal .modal-content {
  border: 1px solid #f3f3f3;
  background: #fafbf8;

  .modal-header {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.163px;
  }

  .modal-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    display: inline-flex;
    align-items: center;

    .back-btn {
      line-height: 0;
    }
  }
}

.outofstock-item {
  display: flex;
  align-items: center;
  padding: 25px;
  border-bottom: 1px solid #f3f3f3;
  transition: all ease 0.8s;

  &:last-child {
    border: 0;
  }

  .outofstock-product-image {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    padding: 15px 5px;
  }

  .img-wrapper {
    width: 54px;
    padding-top: 52px;
    position: relative;
    overflow: hidden;
    height: fit-content;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-overlay {
      transition: all ease 0.8s;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f32d68;
      opacity: 0;

      .btn-remove {
        border-radius: 40px;
        background-color: #ffffff;
        display: flex;
        cursor: pointer;
      }
    }
  }

  .action-btn-wrapper {
    .btn-close {
      height: 5px;
      width: 5px;
      opacity: 0.6;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .outofstock-item-wrapper {
    flex: 1;
    display: flex;
    flex-flow: column;

    h6.cursor-pointer {
      &:hover {
        text-decoration: underline;
      }
    }

    h6,
    p {
      color: #909090;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.138px;
      margin-bottom: 4px;
      display: inline-flex;
      align-items: center;

      span {
        margin-top: 0 !important;
      }
    }
    .title {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.188px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      line-height: 1.1;
    }

    label {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.163px;
      display: flex;
      margin-bottom: 4px;
      align-items: center;
    }
    .product-price-info label {
      color: #909090;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.15px;
      margin-right: 5px;

      &.offered-price-info {
        font-weight: 400;
        margin-right: 0;
      }
    }

    .btn-outline-dark {
      color: #505050;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.163px;
      border-radius: 2px;
      border: 1px solid #eaeaea;
      background: #fff;
      padding: 4px 12px;
      margin-top: 5px;

      height: 30px;

      &.fixed-width {
        width: 95px;
      }
    }

    .green-tg {
      margin: 0 0 0 5px;
      margin-left: 5px;
      font-size: 10px;
      text-align: center;
      width: 10px;
      max-height: 11px;
      border-radius: 1px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 5px 3px;

      &[data-phenotype="Indica"] {
        background-color: #511e84;
      }

      &[data-phenotype="Hybrid"] {
        background-color: #1fa824;
      }

      &[data-phenotype="Sativa"] {
        background-color: #cd001a;
      }

      &[data-phenotype="CBN"] {
        background-color: #72cddc;
      }

      &[data-phenotype="THCV"] {
        background-color: #74bf44;
      }

      &[data-phenotype="CBD"] {
        background-color: #9da7af;
      }
    }
  }

  &:hover {
    background-color: #f4f5f2;

    .img-wrapper {
      .img-overlay {
        opacity: 1;
      }
    }
  }
}

.item-list-wrapper {
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 60px;
}
.modal-action-bar {
  .btn {
    width: calc(100% + 2px);
    position: absolute;
    bottom: -1px;
    left: -1px;
    border-radius: 0;
  }
}

.download-assets-btn-block {
  line-height: 1.1;
  a {
    color: #101010;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
  }
}
