.navbar {
  background-color: #ffffff;
  padding: 10px 0 !important;
  border-bottom: 1px solid #f3f3f3;

  position: fixed !important;
  width: 100%;
  z-index: 11;
  top: 0;

  &.hasMultipleCustomers {
    top: 31px;
  }

  .nav-wrapper {
    flex: 1;

    .brand-navigation {
      flex-wrap: nowrap;
    }
  }

  .nav-link {
    padding: 0 25px !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #101010;
    cursor: pointer;

    &:hover,
    &.active {
      color: #606060 !important;
    }
  }

  .navbar-brand {
    display: flex;
    width: fit-content;
  }

  .nav-cta {
    padding: 0 17px 0 25px;
    color: #000000;
    transition: all ease 0.15s;

    &:hover {
      color: #00000077;
    }

    &:last-child {
      padding-right: 0;
    }

    &.last-item {
      border-right: 1px solid #eaeaea;
      padding-right: 5px;
    }

    &.btn-cart {
      border-left: #eaeaea 1px solid;
    }

    .nav-item.dropdown > a::after {
      content: "";
      display: inline-block;
      height: 12px;
      width: 12px;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border: 0;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  .nav-item .dropdown-menu.show {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 20px 30px rgba(112, 118, 101, 0.1);
    border-radius: 2px;
    padding: 5px 0;
    width: 240px;
    left: unset;
    right: 0;
    margin-top: 15px;
    border: 1px solid #eaeaea;

    .dropdown-item {
      color: #101010;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      padding: 12px 24px;
      white-space: initial;
      overflow-x: hidden;
      border-bottom: 1px solid #eaeaea;

      &.active {
        color: #511e84;
        background-color: #ffffff;
      }

      &:hover {
        background: #fafbf8;
        color: #606060;
      }

      &.highlight {
        color: #f32d68;
        border: 0;
      }
    }

    .dropdown-divider {
      margin: var(--bs-dropdown-divider-margin-y) 24px;
    }
  }

  .btn-cart {
    .quantity {
      position: absolute;
      top: -3px;
      right: 7px;
      padding: 2px;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      border-radius: 100%;
      line-height: 7px;
      background: #198754;
      height: 20px;
      width: 20px;
      border: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .nav-link.dropdown-toggle {
    padding: 6px 11px !important;
    background: #ffffff;
    // border: 1px solid #F3F3F3;
    border-radius: 24px;

    &::after {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    .nav-link {
      padding: 0 10px !important;
    }

    .nav-cta {
      padding: 0 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar-nav {
    display: none !important;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;
  background: #fafbf8;
  transition: all ease 1s;
  overflow-x: hidden;

  &.show {
    left: 0;
  }

  .mobile-menu-header {
    padding: 28px 24px 28px 40px;
    border-bottom: 1px solid #f3f3f3;
    background: #ffffff;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.0125em;
      color: #000000;
    }

    svg {
      color: #000000;
    }
  }

  .mobile-menu-wrapper {
    padding: 30px 0;

    .quantity {
      position: absolute;
      top: 18px;
      right: 24px;
      background: #f32d68;
      color: #ffffff;
      padding: 5px 9px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      border-radius: 30px;
    }

    .mobile-menu-link,
    .mobile-menu-bottom {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      text-decoration: none;
      padding: 20px 40px;
      width: 100%;

      &:nth-child(2n-1) {
        background-color: #ffffff;
      }

      &.active {
        color: #511e84;
      }

      &.logout {
        color: #f32d68;
        background-color: #ffffff;
      }
    }

    .mobile-menu-bottom {
      background-color: #fafbf8 !important;
      &:nth-child(even) {
        background-color: #ffffff !important;
      }
    }

    .mobile-menu-divider {
      margin: 20px 40px;
      color: #f3f3f3;
      opacity: 1;
    }
  }
}

.cart-timer-box {
  padding-right: 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.163px;
  width: 50px;
}

.ar-block.text-center {
  position: fixed;
  top: 67px;
  background: #eb5757;
  z-index: 9;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.175px;
  padding: 5px 15px;

  @media screen and (max-width: 575px) {
    position: relative;
    font-size: 12px;
    top: 116px;
  }

  &.hasMultipleCustomers {
    top: 98px;

    @media screen and (max-width: 575px) {
      top: 146px;
    }
  }
}
