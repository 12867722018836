@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  font-family: "Outfit", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin-bottom: 0;
  color: #222222;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer !important;
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff33;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-layout {
  padding-top: 67px;
  position: relative;

  @media screen and (max-width: 576px) {
    padding-top: 116px;
  }

  &.hasMultipleCustomers {
    padding-top: 98px;
  }
  &.has-ar {
    padding-top: calc(67px + 30px);
    @media screen and (max-width: 576px) {
      padding-top: 116px;
    }
  }

  &.has-ar.hasMultipleCustomers {
    padding-top: 128px;

    @media screen and (max-width: 576px) {
      padding-top: 146px;
    }
  }

  &.checkout-page {
    z-index: 2;
  }
}
