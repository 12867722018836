.list-toggle {
  cursor: pointer;
  border-radius: 5px;
  min-width: fit-content;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  justify-content: space-between;

  .toggle-item {
    padding: 3px 8px;
    // border-radius: 5px;
    color: #808080;
    transition: all ease 0.5s;
    display: flex;
    align-items: center;
    min-width: fit-content;

    &.active {
      color: #000;
      background-color: #ffffff;
    }
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}
