.register-container {
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 239px;
    height: auto;
    color: #FFFFFF;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .term-condition {
    .form-check {
      display: flex;
      align-items: center;

      input {
        margin-top: 0;
        margin-right: 8px;
        border-color: #F3F3F3;

        &:checked {
          background-color: #000000;
          border-color: #000000;
        }

        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
        }
      }
    }

    label, a {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0125em;
      color: #A2A2A2;
    }

    a {
      color: #000000;
    }
  }

  .register-form-inner {
    max-width: 794px;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }

  .btn-create {
    color: #222222;
  }

  .btn-forgot {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
  }

  .btn-primary {
    border-radius: 50px;
    padding: 16px 40px;
    font-size: 13px;
    line-height: 16px;
    background-color: #000000;
    border-color: #000000;
    margin-top: 60px;
    margin-bottom: 20px;
    color: #FFFFFF;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 42px;
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;

    .icon {
      position: absolute;
      right: 20px;
      top: 41px;
      cursor: pointer;
    }
  }

  .form-label {
    font-size: 12px;
    line-height: 15px;
  }

  .form-control {
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    padding: 14px 16px;
    font-size: 13px;
    line-height: 16px;

    &:focus {
      border-color: #000000;
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
    }
  }

  @media screen and (max-width: 992px) {
    .register-form-inner {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    form {
      justify-content: center;
    }
  }

  @media screen and (max-width: 576px) {
    .register-form-inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
