.advertise-container {
  transition: all ease 0.5s;
  margin: 30px auto;

  &.hide {
    opacity: 0;
    display: none;
    visibility: hidden;
  }

  .advertise {
    width: fit-content;
    background-color: #000000;
    padding: 8px 24px;
    position: relative;

    p {
      color: #ffffff;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      margin-right: 30px;
    }

    .btn-secondary {
      padding: 7px 12px;
      color: #000000;
      font-weight: 600;
      font-size: 11px;
      line-height: 14px;
      background-color: #ffffff;
      border-color: #ffffff;
      border-radius: 0;
      margin-right: 16px;
    }

    svg {
      cursor: pointer;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 16px;
      border-bottom: 46px solid transparent;
      border-left: 10px solid #ffffff;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 16px;
      border-top: 46px solid transparent;
      border-right: 10px solid #ffffff;
    }
  }
}
