.search-form-bar {
  background: #fafafa;
  border-radius: 0;
  border: 0;

  input {
    color: #505050;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.163px;
    border: 0;
    background: #fafafa;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      background: #fafafa;
    }
  }
  button {
    border: 0;

    &:hover,
    &:focus,
    &:active {
      background: transparent !important;
      svg {
        color: #505050 !important;
      }
    }
  }
}

#dropdown-custom-components {
  border: 0;
  &:after {
    display: none;
  }
}
.search-form-bar-wrapper {
  width: 100%;
  max-width: 500px;
  margin-right: 25px;

  @media screen and (max-width: 575px) {
    position: absolute !important;
    top: 57px;
    left: 0;
    width: 100%;
    padding: 5px 15px;
    background: #fff;
    max-width: 100%;
    border-bottom: 1px solid #f3f3f3;
    margin-right: 0;
  }

  .dropdown-menu {
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 0;
    max-height: 60vh;
    overflow-y: auto;
    padding: 0;
    min-width: 300px;
    max-width: 100%;
    margin-top: 0 !important;
  }
}

.search-item {
  .item-container {
    display: flex;
    flex-flow: row;
    margin-bottom: 0;
    width: 100%;

    .thc-wrap,
    .product-tags,
    .quick-actions,
    .btn-container {
      display: none !important;
    }

    .img-wrapper {
      padding: 10px;
      width: 85px;
      margin-right: 15px;
      margin-bottom: 0;

      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
        max-width: 80px;
        height: 50px;
        transition: 0.3s all ease;
      }
    }
    .details-wrapper {
      width: 100%;
    }
  }
  &.dropdown-item-text:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  &.dropdown-item-text {
    padding: 15px 15px;
  }

  &:hover,
  &:focus,
  &:active {
    background: #f1f1f1 !important;
    color: #505050 !important;
  }
}
